import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import '../styles/Publikationen.css';

import { useEffect, useRef, useState } from 'react';


function Publikationen()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [PublikationenBody, setPublikationenBody] = useState('PublikationenBody');
    const [PubScreenTitel1, setPubScreenTitel1] = useState('PubScreenTitel1');
    const [PubScreenTitel2, setPubScreenTitel2] = useState('PubScreenTitel2');
    const [PubRowBullet, setPubRowBullet] = useState('PubRowBullet');
    const [PubRowTitel, setPubRowTitel] = useState('PubRowTitel');
    const [RG_Link, setRG_Link] = useState('RG_Link');
    const [KuJM_Link, setKuJM_Link] = useState('KuJM_Link');
    const [SLLD_Link, setSLLD_Link] = useState('SLLD_Link');
    const [Springer_Link, setSpringer_Link]= useState('Springer_Link');
    const [MedienPaed_Link, setMedienPaed_Link] = useState('MedienPaed_Link');
    const [sciendo_Link, setSciendo_Link] = useState('sciendo_Link');
    const [TaylorFrancisLink, setTaylorFrancisLink] = useState('TaylorFrancisLink');
    const [FriedrichLink, setFriedrichLink] = useState('FriedrichLink');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setPublikationenBody('PublikationenBody_Mobile');
            setPubScreenTitel1('PubScreenTitel1_Mobile');
            setPubScreenTitel2('PubScreenTitel2_Mobile');
            setPubRowBullet('PubRowBullet_Mobile');
            setPubRowTitel('PubRowTitel_Mobile');
            setRG_Link('RG_Link_Mobile');
            setSLLD_Link('SLLD_Link_Mobile');
            setSpringer_Link('Springer_Link_Mobile');
            setMedienPaed_Link('MedienPaed_Link_Mobile');
            setKuJM_Link('KuJM_Link_Mobile');
            setSciendo_Link('sciendo_Link_Mobile');
            setTaylorFrancisLink('TaylorFrancisLink_Mobile');
            setFriedrichLink('FriedrichLink_Mobile');
        }

    }, windowSize.current[0])


    return(
    <div className='PublikationenWrapper'>
        <Navigation></Navigation>
        <div className='SiteBackground'></div>
        <div className={PublikationenBody}>
            <div className='PubScreen'>
                <div className={PubScreenTitel1}>Publikationen</div>
                
                <div className={PubScreenTitel2}>Zeitschriftenbeiträge</div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Magirius, Marco & Hesse, Florian & Helm, Gerrit & Scherf, Daniel (2024): KI im Literaturunterricht. Chancen und Herausforderungen zwei Jahre nach Veröffentlichung von ChatGPT. In: <i>Der Deutschunterricht</i> 5/2024. S. 14-23.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.friedrich-verlag.de/friedrich-plus/sekundarstufe/deutsch/sprachgebrauch/ki-im-literaturunterricht-19740"><div className={FriedrichLink}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Hesse, Florian & Helm, Gerrit (2024): Writing with AI in and beyond teacher education: Exploring subjective training needs of student teachers across five subjects. In: <i>Journal of Digital Learning in Teacher Education (JDLTE)</i>. S. 1-14. https://doi.org/10.1080/21532974.2024.2431747</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.tandfonline.com/doi/full/10.1080/21532974.2024.2431747?scroll=top&needAccess=true"><div className={TaylorFrancisLink}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit & Hesse, Florian (2024): Usage and beliefs of student teachers towards artificial intelligence in writing. In: <i>Research in Subject-matter Teaching and Learning (RISTAL)</i>. 7 (1). S. 1-18. https://doi.org/10.2478/ristal-2024-0001</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://sciendo.com/de/article/10.2478/ristal-2024-0001"><div className={sciendo_Link}></div></a>
                    </div>
                </div>
                
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit & Dube, Juliane (2024): Hingeschaut – Bilderbuch-Apps zum literarischen Lernen im inklusiven Deutschunterricht. In: <i>MedienPädagogik. Zeitschrift für Theorie und Praxis der Medienbildung</i>. https://doi.org/10.21240/mpaed/00/2024.03.16.X</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.medienpaed.com/article/view/1616"><div className={MedienPaed_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2022): "Das lokomotische Satzmodell: Satztopologie im Unterricht der Primarstufe" SLLD-U. </div>
                    <div className='PubRowLinkArea'>
                        <a href="https://doi.org/10.46586/SLLD.220"><div className={SLLD_Link}></div></a>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Beiträge in Sammelbänden</div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Gailberger, Steffen & Helm, Gerrit (2024): kluug das Lesen fördern. Digitale systematische Leseförderung für die Grundschule. In: Carl, Mark-Oliver & Jörgens, Moritz & Schulze, Tina (Hrsg.): <i>Literarische Texte lesen – Texte literarisch lesen. Festschrift für Cornelia Rosebrock</i>. J.B. Metzler, S. 143-172.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://link.springer.com/chapter/10.1007/978-3-662-67816-9_9"><div className={Springer_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Dube, Juliane &  Helm, Gerrit & Ronge, Verena (2023): Kindliches Spiel und ästhetische Erfahrungen am Beispiel von Bilderbuch-Apps. Erste Ergebnisse einer rekonstruktiven Fallstudie. In: Dichtl, Eva-Marie & Bernhardt, Sebastian (Hrsg.): <i>Frühkindliches Spiel und literarische Rezeption</i>. Frank & Timme, S. 153-188.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.researchgate.net/publication/373158588_Kindliches_Spiel_und_asthetische_Erfahrungen_mit_polymodalen_Texten_am_Beispiel_von_Bilderbuch-Apps"><div className={RG_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Gailberger, Steffen & Helm, Gerrit & Nix, Daniel (2022): Lesen und Leseförderung in der Primar- und Sekundarstufe. In: Gailberger, Steffen & Wietzke, Frauke (Hrsg.): <i>Handbuch Kompetenzorientierter Deutschunterricht</i>. 2. Aufl. S. 38-93. </div>
                    <div className='PubRowLinkArea'>
                        
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2021): "Das Ausmaß der Unterschiede in den Lese-Items des Salzburger Lese-Screening 2-9" In: Gailberger, Steffen & Sappok, Christopher (Hrsg.): <i>Weiterführende Grundlagenforschung in Lesedidaktik und Leseförderung: Theorie, Empirie, Anwendung.</i> SLLD-B.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://omp.ub.rub.de/index.php/SLLD/catalog/download/189/167/1102?inline=1"><div className={SLLD_Link}></div></a>
                    </div>
                </div>


                <div className={PubScreenTitel2}>Poster</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2024): "AiDe-L: (Browser-)Anwendung zur individuellen Diagnose im Lesen." Poster zum 8. Arbeitstreffen der AG Leseverstehen. </div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.researchgate.net/publication/378434648_AiDe-L_Browser-Anwendung_zur_individuellen_Diagnose_im_Lesen_Poster_zum_8_Arbeitstreffen_der_AG_Leseverstehen_in_Koblenz"><div className={RG_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2018): "Ein Modell des hierarchischen Lesens und seine lesedidaktischen Implikationen." Poster zum 22. Symposion Deutschdidaktik. </div>
                    <div className='PubRowLinkArea'>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Rezensionen</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Hesse Florian & Helm, Gerrit (2024): "Rundisc: Chants of Sennaar" [Rezension]: <i>KinderundJugendmedien.de</i>.</div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.kinderundjugendmedien.de/kritik/computerspiele/7152-rundisc-chants-of-sennar"><div className={KuJM_Link}></div></a>
                    </div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Hesse Florian & Helm, Gerrit (2024): "Black Salt Games: Dregde" [Rezension]: <i>KinderundJugendmedien.de</i>.</div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.kinderundjugendmedien.de/kritik/computerspiele/7223-black-salt-games-dregde"><div className={KuJM_Link}></div></a>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Vorträge (ausgewählte)</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>15.11.2024:</b> [Gemeinsam mit Kirsten Schindler & Alyssa Kutzner] Schreiben mit KI - Perspektiven für die Schule (Keynote beim Zukunftsforum Bildungsforschung 2024)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>5.11.2024:</b> [Gemeinsam mit Florian Hesse] Schreiben mit KI an der Hochschule: Status Quo und Perspektiven nach zwei Jahren ChatGPT (Jahrestagung 2024 des eTeach-Netzwerks Thüringen)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>18.09.2024:</b> KI als Ressource zum Verfassen professioneller Schreibprodukte im Lehramtsstudium (25. Symposion Deutschdidaktik)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>1.07.2024:</b> Self-regulated exploration in educational videogames (Di.ge.LL-Conference Freiburg)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>21.06.2024:</b> Sprachsystematische Lesediagnose und der Transfer in die Leseförderung (1. SDD Postdoc-Akademie, FSU Jena)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>16.03.2023:</b> [Gemeinsam mit Juliane Dube] Ästhetische Erfahrungen im Umgang mit Bilderbuch-Apps (Jahrestagung der AG Medien des Symposion Deutschdidaktik e. V. an der Pädagogischen Hochschule Freiburg)</div>
                </div>
                
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>18.01.2020:</b> Das Erfassen von orthographischen Fertigkeiten von Schülerinnen und Schülern mit Hilfe einer Android-App (FORTE Tagung, Universität Bremen)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>17.01.2020:</b> Lesekompetenz und Lesediagnose (Gastvortrag an der Leuphana Universität Lüneburg)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>8.12.2018:</b> Lesekompetenz und Lesediagnose aus linguistischer Perspektive: Die Graphem-Ebene (11. Nordwestdeutsches Linguistisches Kolloquium, C.v.O Universität Oldenburg)</div>
                </div>

                <div className={PubScreenTitel2}>Workshops und Fortbildungen</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>7.11.2024:</b> Feedback mit KI im Lehramt (Workshop im Rahmen des 1. Jenaer Fachtags Deutsch)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>17.10.2024:</b> Leseförderung im Kontext von Schulentfremdung (Fortbildung für Fachberater:innen im Auftrag des ThILLM)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>12.03.2024:</b> Verben, Kasus, Satzglieder (Fortbildung am Studienseminar Gym/GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>19.10.2023:</b> [Gemeinsam mit Florian Hesse] Schreiben mit KI (Fortbildung im Rahmen der Netzwerktagung Lehrer:innenbildung der FSU Jena)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>11.10.2022:</b> Systematische Leseförderung von der Segmentebene bis zur Ebene des Textes (Fortbildung am Studienseminar Gym/GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>11.11.2021:</b> Von 'Tu-Wörtern', 'Namen-Wörtern' und warum sie nicht so heißen sollten (Fortbildung am Studienseminar Gym/GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>19.11.2020:</b> 'Wörtervengers' - Vom Verb zu den Satzgliedern (Fortbildung am Studienseminar Gym/GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>20.02.2020:</b> Von 'Tu-Wörtern', 'Namen-Wörtern' und warum sie nicht so heißen sollten (Fortbildung am Studienseminar Gym/GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>9.09.2018:</b> vom Wort zum Satz - form-funktionaler Grammatikunterricht in der Primarstufe - Teil 2: Satzarten und Satzglieder (Fortbildung am Studienseminar GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>30.08.2018:</b> vom Wort zum Satz - form-funktionaler Grammatikunterricht in der Primarstufe - Teil 1: Die Wortarten (Fortbildung am Studienseminar GHRS Oldenburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>9.01.2018:</b> Sprache und Gewalt in Reinke Fuchs - Mittelalterliche Literatur und <i>Poetry Slam</i> (Fortbildung am Studienseminar GHRS Lüneburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>7.05.2013:</b> Reineke der Fuchs - Mittelalterliche Literatur und <i>HipHop</i> im Deutschunterricht der Klassen 3-10 (Fortbildung am Studienseminar GHRS Lüneburg)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className={PubScreenTitel2}>Sonstiges</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2022): "Van den vos Reynaerde - Didaktische Aspekte" [Vorwort zu]: Schlusemann, Rita (Hrsg.): <i>Van den vos Reynaerde. Relectiones</i>. Hirzel Verlag.</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
        )
}

export default Publikationen